import React from 'react';
import { render } from 'react-dom'
import Grid from '@material-ui/core/Grid';
import headshot from './images/DSC03594.png'
import twitter from './images/twitter.png'
import medium from './images/medium.png'
import linkedin from './images/linkedin.png'
import github from './images/GitHub-Mark-120px-plus.png'
import gscholar from './images/g-scholar.png'



function About() {
  return (
    <div>

      <br></br><br></br>

      <Grid container item xs={12} spacing={1}>

        <Grid item xs={3}></Grid>
        <Grid item xs={6}>
        <div className="image-headshot">
          <center><img src={headshot} width="200"/></center>
        </div>
        </Grid>
        <Grid item xs={3}></Grid>

        <Grid item xs={3}></Grid>
        <Grid item xs={6}>

          <br></br><br></br>

          <center><p>Texan based in London. Data Scientist interested in shaping technology to help us "human" better.
          </p>
          <p> Currently, massaging data big and small @ <a href="https://www.turing.ac.uk/">The Alan Turing Institue</a>.</p>
          <p>Formerly, DS @ <a href="https://www.numina.co/"> Numina</a>, Founder of <a href="https://www.facebook.com/parkitteam/">ParkIT</a>, and Design Fellow @ <a href="https://www.ideocolab.com/">IDEO CoLab</a>.</p>
          <br></br>
          <p> READING: <a href="https://www.goodreads.com/en/book/show/986428">Computer Power and Human Reason: From Judgment to Calculation</a></p>
          <p>
          <a href="https://twitter.com/jen_gineered"><img src={twitter} width="25"/></a>
          <a href="https://www.linkedin.com/in/jennifer-ding-b693a95a/"><img src={linkedin} width="25"/></a>
          <a href="https://medium.com/@jending"><img src={medium} width="25"/></a>
          <a href="https://github.com/dingaaling/"><img src={github} width="25"/></a>
          <a href="https://scholar.google.com/citations?hl=en&user=m40xnP8AAAAJ/"><img src={gscholar} width="25"/></a>
          </p></center>
          <br></br><br></br><br></br>

        </Grid>

      </Grid>


    </div>
  );
}

export default About;
