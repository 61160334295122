import React from 'react';
import { render } from 'react-dom'
import {Helmet} from 'react-helmet';
import './App.css';
import Home from './Home.js';
import Portfolio from './Portfolio.js';
import Writing from './Writing.js';
import About from './About.js';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

class App extends React.Component {

HOME = 0;
PORTFOLIO = 1;
WRITING = 2;
ABOUT = 3;

  constructor(props) {
    super(props);
    this.state = { pageStatus : this.HOME }
    }

  onTabClick =(tabNumber) => {
    this.setState({ pageStatus: this.state.pageStatus = tabNumber})
  }

render(){
  return (

    <div className="App">

          <Helmet>
              <style>{'body { background-color: #E5E5E5; }'}</style>
          </Helmet>


      <header className="App-header">
          <Grid container item xs={12} spacing={0}>
            <Grid item xs={3}>
            </Grid>
            <Grid item xs={6}>
            <h1><center>JENNIFER DING</center></h1>
            </Grid>
        </Grid>
      </header>

      <Grid container item xs={12} spacing={0}>
        <Grid item xs={12}>
          <center>
              <Tabs
                textColor="#000000"
                centered>
                <Tab label="Home" onClick = {() => this.onTabClick(this.HOME)}/>
                <Tab label="Work" onClick = {() => this.onTabClick(this.WRITING)} />
                <Tab label="Projects" onClick = {() => this.onTabClick(this.PORTFOLIO)}/>
                <Tab label="Here & There" onClick = {() => this.onTabClick(this.ABOUT)}/>
              </Tabs>
          </center>
          </Grid>
      </Grid>


      <header className="App-body">

          {this.state.pageStatus == 0 && (<Home/>)}
          {this.state.pageStatus == 1 && (<Portfolio/>)}
          {this.state.pageStatus == 2 && (<Writing/>)}
          {this.state.pageStatus == 3 && (<About/>)}

      </header>

    </div>
  );
}

}

export default App;
