import React from 'react';
import { render } from 'react-dom'
import Grid from '@material-ui/core/Grid';
import {  Map, TileLayer, Marker, Popup, Circle, CircleMarker } from 'react-leaflet'

const position = [40.416775, -3.703790];


function Home() {
  return (

    <div className="leaflet-container">

    <br></br>


        <Grid item xs={12}>
            <Map center={position} zoom={3}>
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url='http://{s}.tile.osm.org/{z}/{x}/{y}.png'
            />
            <CircleMarker center={[33.019844,  -96.69888]} color="#1ABC9C" radius={15}>
              <Popup>PLANO, TX - HOMETOWN</Popup>
            </CircleMarker>
            <CircleMarker center={[51.509865, -0.118092]} color="#e0115f" radius={15}>
              <Popup>London, UK - CURRENT HOME</Popup>
            </CircleMarker>
            <CircleMarker center={[40.761927, -73.950093]} color="#1ABC9C" radius={10}>
              <Popup>NEW YORK, NY - CORNELL TECH (2018)</Popup>
            </CircleMarker>
            <CircleMarker center={[45.5234515, -122.6762071]} color="#1ABC9C" radius={10}>
              <Popup>PORTLAND, OR - PARKIT HQ (2016)</Popup>
            </CircleMarker>
            <CircleMarker center={[42.375, -71.1061111]} color="#1ABC9C" radius={10}>
              <Popup>CAMBRIDGE, MA - IDEO COLAB FELLOWSHIP (2018)</Popup>
            </CircleMarker>
            <CircleMarker center={[29.749907, -95.358421]} color="#1ABC9C" radius={15}>
              <Popup>HOUSTON, TX - RICE UNIVERSITY</Popup>
            </CircleMarker>
            <CircleMarker center={[11.470099, -86.124924]} color="#1ABC9C" radius={8}>
              <Popup>PLAYA GIGANTE, NICARAGUA - AQUA WELLNESS RESORT (2012)</Popup>
            </CircleMarker>
            <CircleMarker center={[25.286106, 51.534817]} color="#1ABC9C" radius={8}>
              <Popup>DOHA, QATAR - BAKER INSTITUTE PUBLIC DIPLOMACY AND GLOBAL POLICYMAKING PROGRAM (2015)</Popup>
            </CircleMarker>
            <CircleMarker center={[39.9075, 116.39723]} color="#1ABC9C" radius={8}>
              <Popup>BEIJING, CHINA - 老家 </Popup>
            </CircleMarker>
            <CircleMarker center={[30.29365, 120.16142]} color="#1ABC9C" radius={8}>
              <Popup>SHANGHAI, CHINA - 老家 </Popup>
            </CircleMarker>
            <CircleMarker center={[18.33134, -64.79375]} color="#1ABC9C" radius={8}>
              <Popup>ST. JOHN, USVI - VIRGIN ISLANDS NATIONAL PARK TRAIL CREW (2010)</Popup>
            </CircleMarker>
          </Map>
          </Grid>
          )}

    </div>
  );
}

export default Home;
