import React from 'react';
import { render } from 'react-dom'
import Grid from '@material-ui/core/Grid';


function Writing() {
  return (
    <div>


        <Grid container item xs={12}>

            <Grid item xs={3}></Grid>
            <Grid item xs={7}>

              <h2> WRITING </h2>
              <p> <a href="https://www.turing.ac.uk/blog/what-defines-open-open-ai">The Alan Turing Institute</a> | What defines the ‘open’ in ‘open AI’?</p>
              <p> <a href="https://pudding.cool/2021/10/lenna/">The Pudding</a> | Can Data Die? Tracking the Lenna Image.</p>
              <p> <a href="http://medium.com/@jending">Medium</a> | Personal thoughts on startup life, the tech world, and some data deep sea diving. </p>

              <h2> SPEAKING </h2>
              <ul>
                <li> <a href="https://www.iapa.org.au/events/2022-iapa-advancing-analytics.html#program"> IAPA Advancing Analytics Keynote </a> Open Source AI: how distributed global AI collaboratives are creating a new practices of openness beyond open access - Sydney (2022) </li>
                <li> <a href="https://www.turing.ac.uk/events/bigscience-philosophy-building-open-ai"> The Alan Turing Institute Seminar </a> The BigScience philosophy of building open AI - London (2022) </li>
                <li> <a href="https://2022.open-data.nyc/event/citi-map-create-your-own-data-maps/"> NYC Open Data Week Workshop </a> Citi Map: Create Your Own Data Maps - New York City (2022) </li>
                <li> <a href="https://www.partnershiponai.org/how-should-human-rights-advocacy-balance-the-opportunities-risks-of-artificial-intelligence/"> AAAS Human Rights & AI Panel </a> How Should Human Rights Advocacy Balance the Opportunities & Risks of Artificial Intelligence? - Washington DC (2019) </li>
                <li> <a href="https://twitter.com/layne_celeste/status/1182677040337739776/photo/1"> Columbia GSAPP Digital Urbanisms Panel </a> Infrastructures: Digital Materiality - New York City (2019)</li>
                <li> <a href="https://twitter.com/gehlinstitute/status/1101936720461942785"> NYC Open Data Week Panel </a> Smart City, Public Spaces: Privacy & Open Data in the Public Realm - New York City (2019)</li>
                <li> <a href="https://dallasinnovates.com/dallas-women-win-144k-at-wework-creator-awards/"> WeWork Creator Awards Winner </a> - Austin (2017)</li>
              </ul>

              <h2> ART </h2>
              <ul>
                <li> Tracking Lenna: Data Practices from 1972 to 2022 at the Oxford Internet Institute <a href="https://connectedlife.oii.ox.ac.uk/agenda/">Connected Life Conference</a> (2022)</li>
                <li> <a href="https://github.com/dingaaling/webcam-mirror">Data Mirror:</a> reflecting back your NYC/FB data twins at the<a href="https://datathroughdesign.com/2020"> Data x Design Exhibit </a>during NYC Open Data Week (2020)</li>
                <li> <a href="https://privacy.shorensteincenter.org/cryptocomic">Crypto Key Storage Comic</a> created at the IDEO CoLab and featured in Harvard Shorenstein Center's Privacy Design Forecast (2019)</li>
              </ul>

              <h2> RESEARCH</h2>
              <p> <a href="https://scholar.google.com/citations?hl=en&user=m40xnP8AAAAJ/">Google Scholar link</a> and selected work below:</p>
              <ul>
                <li> <a href="https://arxiv.org/pdf/2301.08488"> Towards Openness Beyond Open Access: User Journeys through 3 Open AI Collaboratives</a> NeurIPS Workshop (2022)  </li>
                <li> <a href="https://drive.google.com/file/d/1LuvYr51tZzb9vwzhhFXBNFpliFGmQ0G2/view?usp=sharing"> Blue Scale: Early Detection of Impending CHF Events Via Wireless Daily Self-Monitoring </a> IEEE EMBS Conference (2014) </li>
                <li> <a href="https://drive.google.com/file/d/1FpsaTUolOlj1yM7w2lUdkN0S5sx4zQp2/view?usp=sharing"> Vision for a Better Connected Seaport: Accessibility to the South Boston Waterfront </a> MIT Policy Hackathon (2018) </li>
                <li> <a href="https://www.bakerinstitute.org/media/files/files/5d29305d/PDGP-pub-QatarReport-110215.pdf">Youth Entrepreneurship in Qatar (Baker Institute) </a> – The Doha Conference (2015) </li>
              </ul>

            <br></br>

          </Grid>
        </Grid>

    </div>
  );
}

export default Writing;
