import React from 'react';
import { render } from 'react-dom'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Grid from '@material-ui/core/Grid';
import portraits from './images/portraits.png'
import ues from './images/ues.png'
import year_in_masks from './images/yearinmasks.png'
import vaccine from './images/fullyvacc.png'
import tram_tracker from './images/tram_tracker.jpeg'

function Portfolio() {
  return (
    <div>

      <br></br><br></br>

      <Grid container item xs={12}>

          <Grid item xs={4}></Grid>
          <Grid item xs={4}>
              <Carousel width={"20"} partialVisbile infiniteLoop>
                        <div>
                            <img src={ues} />
                            <p className="legend">Mapping NYC Mask Behavior. Learn more <a href="https://jending.medium.com/mapping-mask-behavior-in-your-neighborhood-a5ab15380761">here</a></p>
                        </div>
                        <div>
                            <img src={portraits} />
                            <p className="legend">Getting to know your Facebook/NYC Data Twins. Learn more <a href="https://medium.com/@jending/getting-to-know-your-data-twins-1adeabdfaaff">here</a></p>
                        </div>
                        <div>
                            <img src={tram_tracker} />
                            <p className="legend">TramTracker: real-time tracking of the Roosevelt Island Tram. Learn more <a href="https://github.com/dingaaling/tramTracker">here</a></p>
                        </div>
                        <div>
                            <img src={year_in_masks} />
                            <p className="legend">Data Viz: A Year in Masks</p>
                        </div>
                        <div>
                            <img src={vaccine} />
                            <p className="legend">Data Viz: When Am I Fully Vaccinated?</p>
                        </div>
              </Carousel>
          </Grid>
          <Grid item xs={4}></Grid>
      </Grid>

        <br></br><br></br>

    </div>
  );
}

export default Portfolio;
